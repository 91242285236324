<template>
  <ipxx-verify-email-form
    :user-email="email"
  />
</template>

<script>
import IpxxVerifyEmailForm from '@/components/VerifyEmailForm';

export default {
  name: 'VerifyEmailFormTest',
  components: {
    IpxxVerifyEmailForm,
  },
  data() {
    return {
      email: null,
    };
  },
  created() {
    const { email } = this.$route.params;
    if (email) {
      this.email = 'test@test.de';
    }
  },
};
</script>
